var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "animate-onscroll",
          rawName: "v-animate-onscroll",
          value: _vm.animate
            ? "animate__animated animate__slideUpAndAppear animate__fast"
            : "",
          expression:
            "animate ? 'animate__animated animate__slideUpAndAppear animate__fast' : ''",
        },
      ],
      staticClass: "dx-image",
      class: _vm.classes,
    },
    [
      _vm.contentElement.drop
        ? _c(
            "div",
            { staticClass: "w-11/12 md:w-10/12 lg:w-9/12 relative" },
            [
              _vm.contentElement.drop
                ? _c("dx-drop", {
                    attrs: {
                      text: _vm.contentElement.text,
                      type: _vm.contentElement.drop,
                      relative: false,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("dx-asset", {
                staticClass: "w-full dx-image__img",
                class: { "hidden lg:flex": _vm.contentElement.imageSm },
                attrs: { image: _vm.contentElement.image, animate: false },
              }),
              _vm._v(" "),
              _vm.contentElement.imageSm
                ? _c("dx-asset", {
                    staticClass: "lg:hidden w-full dx-image__img",
                    attrs: {
                      image: _vm.contentElement.imageSm,
                      animate: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.contentElement.text
        ? _c("dx-asset", {
            staticClass: "w-full dx-image__img",
            attrs: { image: _vm.contentElement.image },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }