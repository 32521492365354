













import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import { Component, Prop, Vue } from 'vue-property-decorator';
import DxAsset from "../../../../../Component/Common/Assets/Image/Image.vue";
import DxDrop from "../../../../../Component/Common/Drop/Drop.vue";

@Component({
    name: 'dx-image',
    components: {DxAsset, DxDrop}
})
export default class Image extends Vue
{
    @Prop() readonly context: ContentElementContext;
    @Prop({default: true}) readonly animate: boolean;

    _contentElement: any;

    created()
    {
        // console.log('[Image] _contentElement' , this.context.data.getAll());
        this._contentElement = this.context.data.getAll();
    };

    get contentElement()
    {
        return this._contentElement
    }

    get classes()
    {
        let classes = '';
        if (this._contentElement.spacer)
        {
            classes += this._contentElement.spacer.margin + ' ' + this._contentElement.spacer.padding;
        }

        if (this._contentElement.text)
        {
            classes += ' dx-image--drop';
        }

        if (this._contentElement.drop)
        {
            classes += ' dx-image--' + this._contentElement.drop;
        }

        if (this._contentElement.background)
        {
            classes += ' ' + this._contentElement.background;
        }

        classes += ' dx--container-ultra-wide';

        return classes;
    }
};
